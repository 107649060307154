.video {
  max-width: var(--video-max-width);
  max-height: var(--video-max-height);
  margin: var(--video-margin-top) auto;
  margin-bottom: var(--video-margin-bottom);
  display: block;
  background-color: var(--video-background-color);
}

.steps-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--steps-margin-top);
  padding: 0 var(--steps-padding-horizontal);
  text-align: left;
  justify-content: center;
}
