.breathe-instruction {
  font-family: 'Maragsa Display', sans-serif;
  text-align: center;
}

.repetition-counter {
  font-family: 'Hanken Grotesk', sans-serif;
  text-align: center;
  margin-bottom: var(--repetition-counter-margin-bottom);
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--button-container-gap);
  margin-bottom: var(--button-container-margin-bottom);
}

.control-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: var(--control-button-width);
}

#main-button {
  width: var(--main-button-width);
  padding: var(--main-button-padding);
}

.subtext {
  font-family: 'Hanken Grotesk', sans-serif;
  text-align: center;
  margin-top: var(--subtext-margin-top);
}
