@font-face {
    font-family: 'Hanken Grotesk';
    src: url('./fonts/Hanken_Grotesk/static/HankenGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hanken Grotesk Bold';
    src: url('./fonts/Hanken_Grotesk/static/HankenGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Maragsa Display';
    src: url('./fonts/Maragsâ/Maragsâ-Display.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Global Variables */
  :root {
    --primary-color: #D66660;
    --hold-color: rgb(210, 113, 22);
    --button-text-color: #FFF;
    --text-color: #6D4C41;
    --tile-background-color: #fff5e9;
    --video-background-color: #000;
    --header-font-color: #5C4036;
    --subheader-font-color: #963817;

    --base-font-size: 16px; /* Root font size */
    --rem-base: 1rem; /* Equivalent to 16px */

    /* Multiples of 4 in rem */
    --rem-4: 0.25rem;   /* 4px */
    --rem-8: 0.5rem;    /* 8px */
    --rem-12: 0.75rem;  /* 12px */
    --rem-16: 1rem;     /* 16px */
    --rem-20: 1.25rem;  /* 20px */
    --rem-24: 1.5rem;   /* 24px */
    --rem-32: 2rem;     /* 32px */
    --rem-40: 2.5rem;   /* 40px */
    --rem-48: 3rem;     /* 48px */
    --rem-60: 3.75rem;  /* 60px */
    --rem-80: 5rem;     /* 80px */
    --rem-100: 6.25rem; /* 100px */
    --rem-160: 10rem;   /* 160px */
    --rem-280: 17.5rem; /* 280px */

    /* --header-font-size: 2rem;
    --subheader-font-size: 1.5rem;
    --text-font-size: 1.25rem;
    --button-font-size: 1rem;
    --button-padding: 0.9375rem 1.875rem;
    --button-radius: 0.3125rem;
    --container-margin-top: 5%;
    --container-margin-bottom: 10%;
    --circle-size: 3.125rem;
    --circle-opacity: 0.4;
    --flower-size: 12.5rem;
    --animation-duration: 5s;
    --animation-timing-function: ease-in-out; */
    --video-max-height: 28vh;
    --video-max-width: 100%;
    --video-margin-top: var(--rem-20); /* 48px */
    --video-margin-bottom: var(--rem-20); /* 20px */
  
    --steps-margin-top: var(--rem-20); /* 20px */
    --steps-padding-horizontal: var(--rem-20); /* 20px */

    /* Specific values */
    --container-width: 90%;
    --container-margin-top: var(--rem-8);
    --container-margin-bottom: var(--rem-8);

    /* Font sizes */
    --header-font-size: var(--rem-32); /* 32px */
    --subheader-font-size: var(--rem-24); /* 24px */
    --text-font-size: var(--rem-16); /* 16px */

    /* Margins */
    --header-margin-bottom: var(--rem-8); /* 8px */
    --separated-text-margin-top: var(--rem-20); /* 20px */
    --tile-container-margin-top: var(--rem-8); /* 8px */
    --tile-content-margin: var(--rem-32) auto;
    --tile-content-padding-bottom: var(--rem-20); /* 20px */

    /* Home button size */
    --home-button-size: var(--rem-48); /* 48px */

    /* Tile dimensions */
    --tile-border-radius-top: var(--rem-48); /* 48px */
    --tile-padding: var(--rem-8) var(--rem-20); /* 8px 20px */
    --tile-top-position: 40%; /* 40% of the viewport height */
    --tile-min-height: 60vh; /* 60% of the viewport height */
    --tile-content-width: 90%;

    --header-image-width: var(--rem-160); /* 160px */
    --home-nav-margin-top: var(--rem-20); /* 20px */
  
    --navigation-font-size: var(--rem-16); /* 16px */
    --navigation-border-radius: var(--rem-8); /* 8px */
    --navigation-padding: var(--rem-20); /* 20px */
    --navigation-margin-bottom: var(--rem-12); /* 12px */

    /* --headshot-width: var(--rem-280); 256px */
    --headshot-max-height: 28vh;
    --headshot-margin-top: var(--rem-20); /* 48px */
    --headshot-margin-bottom: var(--rem-20); /* 20px */
    --headshot-border-width: var(--rem-8); /* 8px */

    --repetition-counter-margin-bottom: var(--rem-20); /* 20px */
  
    --button-container-gap: var(--rem-16); /* 16px */
    --button-container-margin-bottom: var(--rem-20); /* 20px */
    
    --control-button-width: var(--rem-32); /* 32px */
    
    --main-button-width: var(--rem-48); /* 48px */
    --main-button-padding: var(--rem-12); /* 12px */
    
    --subtext-margin-top: var(--rem-12); /* 12px */

    --flower-size: 12.5rem; /* 200px */
    --circle-size: 3.125rem; /* 50px */
    --circle-opacity: 0.4;
  
    --animation-duration: 5s;
    --color-transition-duration: 5s; /* Updated to match the animation duration */
    --breathe-regular-scale: 1.2;
  }