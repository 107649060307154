.home-header {
  font-family: 'Hanken Grotesk', sans-serif;
}

.header-image {
  width: var(--header-image-width);
  align-self: flex-start;
  clip-path: polygon(0% 10%, 100% 10%, 100% 90%, 0% 90%);
}

.home-nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--home-nav-margin-top);
  width: 100%;
}

.navigation {
  background-color: var(--primary-color);
  font-size: var(--navigation-font-size);
  font-weight: bold;
  border: none;
  border-radius: var(--navigation-border-radius);
  width: 100%;
  padding: var(--navigation-padding);
  margin-bottom: var(--navigation-margin-bottom);
  color: var(--button-text-color);
  cursor: pointer;
  font-family: 'Hanken Grotesk Bold', sans-serif;
  text-align: center;
  align-self: flex-start;
}
