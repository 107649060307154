html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Hanken Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: scroll;
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 100vh; 
  overflow-y: auto; 
}

.page-container {
  width: var(--container-width);
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.scrollable-content {
  overflow-y: scroll;
  margin: auto;
  flex: 1;
  padding: var(--rem-20);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  text-align: left; 
  margin-top: var(--container-margin-top);
  margin-bottom: var(--container-margin-bottom);
}

.home-button {
  position: fixed;
  top: var(--rem-20);
  left: var(--rem-20);
  width: var(--home-button-size); 
  height: var(--home-button-size);
  cursor: pointer;
  z-index: 1000;
}

.header {
  font-size: var(--header-font-size);
  font-weight: bold;
  color: var(--header-font-color);
  text-align: left;
  margin: 0;
  margin-bottom: var(--header-margin-bottom);
}

.subheader {
  font-size: var(--subheader-font-size);
  color: var(--subheader-font-color);
  text-align: left;
  margin: 0;
}

.body-text {
  font-size: var(--text-font-size);
  text-align: left;
  font-family: 'Hanken Grotesk', sans-serif;
}

.main-text {
  margin-bottom: 0;
}

.separated-text {
  margin-top: var(--separated-text-margin-top);
}

.tile-container {
  margin: 0;
  margin-top: var(--tile-container-margin-top);
  background-color: var(--tile-background-color);
  border-top-left-radius: var(--tile-border-radius-top);
  border-top-right-radius: var(--tile-border-radius-top);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: var(--tile-padding);
  position: absolute;
  left: 0;
  right: 0;
  top: var(--tile-top-position);
  min-height: var(--tile-min-height); 
  height: auto; 
  overflow: hidden; 
}

.tile-content {
  display: flex;
  flex-direction: column;
  width: var(--tile-content-width);
  margin: var(--tile-content-margin);
  padding-bottom: var(--tile-content-padding-bottom); 
}

.tile-header {
  font-family: 'Maragsa Display', serif;
}


